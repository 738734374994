import React, { useContext, useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import CheckIn from './pages/CheckIn'
import { Questionnaire } from './pages/Questionnaire'
import { UserProfile } from './pages/UserProfile'
import { RegulatoryInformation } from './pages/RegulatoryInformation'
import { CrisisInformation } from './pages/CrisisInformation'
import Onboarding from 'registration/pages/Onboarding/'
import { useAuth } from 'registration/context/auth'
import PractitionerCallback from './components/PractitionerCallback'
import DemoStartPatientJourney from 'registration/pages/Demo/DemoStartPatientJourney'
import NhsSingleSignOnRedirect from 'registration/pages/NhsSingleSignOnRedirect'
import { RegistrationProvider } from '../../registration/context/registration/registration-provider'
import { PendoProvider } from './context/pendo'
import { LoadReportV2 } from './pages/LoadReportV2'
import { RefundPolicy } from './pages/RefundPolicy'
import { Splash } from './pages/Splash'
import { OrganisationContext } from './context/organisation'
import { useOnboardingState } from 'registration/context/onboarding/onboarding-context'

type IRoutesProps = {
    setReferralPublicId: React.Dispatch<React.SetStateAction<string | undefined>>
} & React.ComponentPropsWithoutRef<'div'>

export const AppRoutes: React.FC<IRoutesProps> = ({ setReferralPublicId }) => {
    const { isD2CUser } = useAuth()
    const organisation = useContext(OrganisationContext)
    const [questionId, setQuestionId] = useState('') // todo: create context + reducer
    const { askOnboardingQuestionsAgain } = useOnboardingState()

    if (askOnboardingQuestionsAgain === null) {
        return <Splash />
    }

    return (
        <Routes>
            <Route
                path="/assessment/question"
                element={
                    questionId === '' ? (
                        <Navigate to="/welcome" />
                    ) : (
                        <Questionnaire
                            questionId={questionId}
                            setQuestionId={setQuestionId}
                            setReferralPublicId={setReferralPublicId}
                        />
                    )
                }
            />
            <Route
                path="demo/report/:id"
                element={
                    <PendoProvider>
                        <LoadReportV2 />
                    </PendoProvider>
                }
            />
            <Route
                path="/report/:id"
                element={
                    <PendoProvider>
                        <LoadReportV2 />
                    </PendoProvider>
                }
            />
            <Route
                path="/welcome"
                element={
                    !askOnboardingQuestionsAgain || isD2CUser || organisation?.demo ? (
                        <CheckIn setQuestionId={setQuestionId} />
                    ) : (
                        <Navigate to="/onboarding" />
                    )
                }
            />
            <Route
                path="/federated-sign-in"
                element={
                    <RegistrationProvider>
                        <NhsSingleSignOnRedirect />
                    </RegistrationProvider>
                }
            />
            <Route
                path="/demo/:demoOrgCode?/start-patient-journey"
                element={!askOnboardingQuestionsAgain ? <DemoStartPatientJourney /> : <Navigate to="/onboarding" />}
            />
            <Route
                path="/onboarding"
                element={
                    !askOnboardingQuestionsAgain && organisation?.demo ? (
                        <DemoStartPatientJourney />
                    ) : (
                        <Onboarding setReferralPublicId={setReferralPublicId} />
                    )
                }
            />
            <Route path="/help" element={<CrisisInformation />} />
            <Route path="/crisisinformation" element={<CrisisInformation />} />
            <Route path="/userprofile" element={<UserProfile />} />
            <Route path="/regulatory-information" element={<RegulatoryInformation />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/practitioner-callback" element={<PractitionerCallback />} />
            <Route path="/" element={<Navigate to="/welcome" />} />
            <Route
                path="/your-report/:id"
                element={
                    <PendoProvider>
                        <LoadReportV2 patientFacing={true} />
                    </PendoProvider>
                }
            />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    )
}
