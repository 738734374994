import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Modal } from '@psyomics/components'
import Styles from './TopNavBar.module.scss'
import * as Sentry from '@sentry/react'
import { Maybe, AllExistingReports } from 'censeo-core/dist'
import { useMutation } from '@apollo/client'
import { authoriseReportMutation, AuthoriseReportResponse } from 'data/mutations/authoriseReport'
import CheckCircleIcon from '../CheckCircleIcon'

interface TopNavBarProps {
    onHomepageClick?: () => void
    settings?: {
        enablePrevButton: boolean
        enableHomepageButton: boolean
    }
    reportSelect?: {
        currentAssessmentId: string
        allCompletedReportsForUser: Maybe<Maybe<AllExistingReports>[]>
    }
    isPatientFacingReportAuthorised?: boolean
    setIsAuthorisedForPatient?: React.Dispatch<React.SetStateAction<boolean | undefined>>
    currentAssessmentId?: string
}

const TopNavBar: React.FC<TopNavBarProps> = ({
    onHomepageClick,
    settings = { enablePrevButton: true, enableHomepageButton: true },
    reportSelect,
    isPatientFacingReportAuthorised,
    setIsAuthorisedForPatient,
    currentAssessmentId,
}) => {
    const navigate = useNavigate()
    const [shareError, setShareError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [authoriseReport] = useMutation<AuthoriseReportResponse>(authoriseReportMutation)
    const [showShareConfirmationModal, setShowShareConfirmationModal] = useState(false)

    const handleShareClick = () => {
        setShareError(false)
        setShowShareConfirmationModal(true)
    }

    const handleConfirm = () => {
        setShowShareConfirmationModal(false)
        if (setIsAuthorisedForPatient) {
            setLoading(true)
            authoriseReport({ variables: { assessmentID: currentAssessmentId } })
                .then((response) => {
                    if (response.data?.authoriseReport) {
                        setIsAuthorisedForPatient(true)
                    }
                })
                .catch((error) => {
                    const errMsg = 'Error authorising report:'
                    console.error(errMsg, error)
                    setShareError(true)
                    Sentry.captureException(errMsg, error)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    return (
        <div className={Styles.buttonGroup}>
            <Button
                appearance="link"
                size="large"
                icon="arrow-left"
                disabled={settings.enablePrevButton ? undefined : true}
                onClick={() => navigate(-1)}
                aria-label="Previous page"
            />
            <Button
                appearance="link"
                size="large"
                icon="home-button"
                disabled={settings.enableHomepageButton ? undefined : true}
                onClick={onHomepageClick ? onHomepageClick : () => navigate(`/demo/experience`)}
                aria-label="Homepage"
            />
            {reportSelect &&
                reportSelect.allCompletedReportsForUser &&
                reportSelect.allCompletedReportsForUser.length > 1 && (
                    <>
                        <label style={{ paddingLeft: '14px' }}>Report</label>
                        <select
                            id="select-for-completed-reports"
                            className={Styles.reportSelect}
                            value={reportSelect.currentAssessmentId}
                            onChange={(event) => {
                                navigate('/report/' + event.target.value)
                            }}
                        >
                            {reportSelect.allCompletedReportsForUser
                                .slice()
                                .sort(
                                    (a, b) =>
                                        (b?.createdAt ? +new Date(b.createdAt) : -1) -
                                        (a?.createdAt ? +new Date(a.createdAt) : -1)
                                )
                                .map((report) => {
                                    if (!report?.assessmentId)
                                        Sentry.captureException(
                                            'No assessment id in list of reports for user ' +
                                                JSON.stringify(reportSelect.allCompletedReportsForUser)
                                        )
                                    if (!report?.createdAt)
                                        Sentry.captureException(
                                            'No createdAt in list of reports for user ' +
                                                JSON.stringify(reportSelect.allCompletedReportsForUser)
                                        )
                                    return (
                                        <option key={report?.assessmentId} value={report?.assessmentId}>
                                            {(() => {
                                                if (!report?.createdAt) return 'Unknown Date'
                                                const date = new Date(report?.createdAt)
                                                const day = String(date.getDate()).padStart(2, '0')
                                                const month = String(date.getMonth() + 1).padStart(2, '0')
                                                const year = date.getFullYear()
                                                return `${day}/${month}/${year}`
                                            })()}
                                        </option>
                                    )
                                })}
                        </select>
                    </>
                )}
            {isPatientFacingReportAuthorised === false && (
                <div className={Styles.share}>
                    <Button
                        appearance="secondary"
                        size="small"
                        label="Share with patient"
                        onClick={handleShareClick}
                        loading={loading}
                        aria-label="Share with patient"
                    />
                </div>
            )}
            {isPatientFacingReportAuthorised === true && (
                <div className={Styles.share}>
                    <CheckCircleIcon label="Report shared with patient" />
                </div>
            )}
            {showShareConfirmationModal && (
                <Modal
                    header="Please confirm"
                    showActions={true}
                    onConfirm={handleConfirm}
                    onClose={() => setShowShareConfirmationModal(false)}
                >
                    Are you sure you want to share the report with this patient?
                </Modal>
            )}
            {shareError && (
                <Modal header="Something went wrong" onClose={() => setShareError(false)}>
                    Failed to share the report with the patient. Please try again or contact{' '}
                    <a href="mailto:support@censeo.co.uk">support@censeo.co.uk</a> to resolve this issue.
                </Modal>
            )}
        </div>
    )
}

export default TopNavBar
